import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../models/user/user.model';
import { UserHelperService } from '../services/helpers/user-helper.service';

@Pipe({
  name: 'userNameOrEmail',
  standalone: true
})
export class UserNameOrEmailPipe implements PipeTransform {

  constructor(
    private uHelper: UserHelperService
  ) {}

  transform(user?: Partial<IUser> | null): string {
    return user?.name || user?.surname ? this.uHelper.getFullName(user) : `${user?.email}`;
  }

}
